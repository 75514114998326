import { styled, Typography } from "@mui/material";
import SectionStyle from "../../styles/SectionStyle";
import Logo from "../../components/Logo";

const PPContainer = styled(SectionStyle)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
}));
const LogoContainer = styled("div")(() => ({
    marginTop: "120px",
    marginBottom: "70px",
    display: "flex",
    justifyContent: "center",
}));
const PPTitle = styled(Typography)(({theme}) => ({
    fontFamily: "InterBold,sans-serif !important",
    fontSize: "35px",
    fontWeight: 800,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
        fontSize: "25px",
        fontWeight: "400",
    },
}));
const PPSectionContainer = styled("ol")(({theme}) => ({
    width: "70%",
    paddingInlineStart: "20px",
    [theme.breakpoints.down("md")]: {
        width: "95%",
    },
}));
const PPSection = styled("li")(({theme}) => ({
    fontFamily: "InterBold,sans-serif !important",
    fontSize: "22px",
    listStyleType: "none",
    [theme.breakpoints.down("md")]: {
        fontWeight: "400",
    },
}));
const PPSectionTitle = styled(Typography)(({theme}) => ({
    marginTop: "30px",
    fontFamily: "InterBold,sans-serif !important",
    fontSize: "22px",
    textTransform: "uppercase",
    fontWeight: 800,
    [theme.breakpoints.down("md")]: {
        fontWeight: "400",
    },
}));
const PPSubSectionContainer = styled("ol")(({theme}) => ({
    width: "100%",
    paddingInlineStart: 0,
    [theme.breakpoints.down("md")]: {
        width: "95%",
    },
}));
const PPSectionSubP = styled("p")(() => ({
    fontSize: "15px",
}));

function PP() {
    return (
        <PPContainer>
            <LogoContainer>
                <Logo sx={{width: 200, height: 200, objectFit: "fill"}}/>
            </LogoContainer>
            <PPTitle>Privacy Policy</PPTitle>
            <PPSectionContainer>
                <PPSectionSubP></PPSectionSubP>
                <PPSectionTitle>Introduction</PPSectionTitle>
                <PPSectionSubP>Our privacy policy will help you understand what information we collect at Z Mileyi, how Z Mileyi uses it, and what choices you have. Z Mileyi built the Z Mileyi app as a free app. This SERVICE is provided by Z Mileyi at no cost and is intended for use as is. If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible in our website, unless otherwise defined in this Privacy Policy.</PPSectionSubP>
                <PPSection>
                    <PPSectionTitle>Information Collection and Use</PPSectionTitle>
                    <PPSubSectionContainer>
                        <PPSectionSubP dangerouslySetInnerHTML={{
                            __html: `
                            For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to users name, email address, gender, location, pictures. The information that we request will be retained by us and used as described in this privacy policy. The app does use third party services that may collect information used to identify you.
                            `
                        }}/>
                    </PPSubSectionContainer>
                </PPSection>
                <PPSection>
                    <PPSectionTitle>Type of personal data we collect and process</PPSectionTitle>
                    <PPSubSectionContainer>
                        <PPSectionSubP dangerouslySetInnerHTML={{
                            __html: `
                            Name, telephone number, e-mail address, feedback ratings and/or payment information of the app user; Geographical location of the user, the time of provision of service and the destination; Identification data of the device on which the Z Mileyi app has been installed;
                            `
                        }}/>
                    </PPSubSectionContainer>
                </PPSection>
                <PPSection>
                    <PPSectionTitle>Disclosure of personal data to third parties</PPSectionTitle>
                    <PPSubSectionContainer>
                        <PPSectionSubP dangerouslySetInnerHTML={{
                            __html: `
                            Users' personal data will be visible only to drivers who have activated the Z Mileyi app; What data will they actually see? Among other data, the name, telephone number and geographical location of the user. During the provision of the service, this data will remain visible. On the other hand, after the provision of the service, the name of the user may remain visible to the driver. This data will help the drivers solve any problem related to the provision of the service, for example to contact the user in order to deliver an object forgotten in the driver's car; What possible situations may require the disclosure of personal data:

                            <br><br>
                            1/ Your personal data will be entitled to be disclosed to entities such as a government authority or a regulatory body or a security agency in the context of a security matter or the investigation of a crime presumed if we are seized.

                            <br><br>
                            2/ Users' personal data may be forwarded to relevant local entities providing Z Mileyi app services. But it is understood that any collection and processing of personal data by Z Mileyi's local service provider partners is carried out under the same conditions as those set out in this Privacy Policy. For commercial and marketing purposes, we may transmit your personal data to commercial partners.
                            `
                        }}/>
                    </PPSubSectionContainer>
                </PPSection>
                <PPSection>
                    <PPSectionTitle>The purpose of collecting and the period of processing of personal data</PPSectionTitle>
                    <PPSubSectionContainer>
                        <PPSectionSubP dangerouslySetInnerHTML={{
                            __html: `
                            When collected and processed, personal data makes it possible to connect a user with a driver; data on the geographical location and the user's telephone number, when transmitted to the driver allow to assign the user the driver closest to him. Other purpose: it is to solve problems related to the quality of service of the driver, to inform about updates of the Z Mileyi application and to make practical suggestions to the drivers. We may use your data for advertising and marketing purposes.
                            `
                        }}/>
                    </PPSubSectionContainer>
                </PPSection>
                <PPSection>
                    <PPSectionTitle>Cookies</PPSectionTitle>
                    <PPSubSectionContainer>
                        <PPSectionSubP dangerouslySetInnerHTML={{
                            __html: `
                            Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your devices’s internal memory.

                            <br><br>
                            This Services does not uses these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collection information and to improve their services. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                            `
                        }}/>
                    </PPSubSectionContainer>
                </PPSection>
                <PPSection>
                    <PPSectionTitle>Device Information</PPSectionTitle>
                    <PPSubSectionContainer>
                        <PPSectionSubP dangerouslySetInnerHTML={{
                            __html: `
                            We collect information from your device in some cases. The information will be utilized for the provision of better service and to prevent fraudulent acts. Additionally, such information will not include that which will identify the individual user.
                            `
                        }}/>
                    </PPSubSectionContainer>
                </PPSection>
                <PPSection>
                    <PPSectionTitle>Service Providers</PPSectionTitle>
                    <PPSubSectionContainer>
                        <PPSectionSubP dangerouslySetInnerHTML={{
                            __html: `
                            We may employ third-party companies and individuals due to the following reasons :
                            <br><br>
                            <ul>
                                <li>To facilitate our Service;</li>

                                <li>To provide the Service on our behalf;</li>

                                <li>To perform Service-related services; or</li>

                                <li>To assist us in analyzing how our Service is used.</li>
                            </ul>
                            <br><br>
                            We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                            `
                        }}/>
                    </PPSubSectionContainer>
                </PPSection>
                <PPSection>
                    <PPSectionTitle>Security of data</PPSectionTitle>
                    <PPSubSectionContainer>
                        <PPSectionSubP dangerouslySetInnerHTML={{
                            __html: `
                            It is true, the transmission of information via the Internet is not completely secure but z Mileyi does its best to ensure the confidentiality and security of the personal data processed and to prevent any illegal use or access by unauthorized persons. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site. As soon as we receive your information, we will seek to prevent unauthorized access, using strict procedures and security features.
                            `
                        }}/>
                    </PPSubSectionContainer>
                </PPSection>
                <PPSection>
                    <PPSectionTitle>Deletion of data</PPSectionTitle>
                    <PPSubSectionContainer>
                        <PPSectionSubP dangerouslySetInnerHTML={{
                            __html: `
                            Uninstalling the z Mileyi app from your device does not terminate your account. It does not delete your personal data. What if you want to delete your personal data collected by z Mileyi? Send us by e-mail, a request to terminate your account, specifying the deletion of personal data. Once this request has been executed, you will no longer be able to use the services of z Mileyi with your existing account and your personal data will no longer have an identity. If you suspect that we (or any other z Mileyi entity processing your personal data) are carrying out such processing contrary to your personal rights or contrary to the law,
                            <br><br>
                            - ask us for an explanation;- ask us for an explanation;
                            <br>
                            - ask us to remedy the situation that has arisen (in particular by blocking, correcting or completing the personal data);
                            <br>
                            - appeal directly to a data protection authority having jurisdiction over your personal data.
                            `
                        }}/>
                    </PPSubSectionContainer>
                </PPSection>
                <PPSection>
                    <PPSectionTitle>Children’s Privacy</PPSectionTitle>
                    <PPSubSectionContainer>
                        <PPSectionSubP dangerouslySetInnerHTML={{
                            __html: `
                            This Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
                            `
                        }}/>
                    </PPSubSectionContainer>
                </PPSection>
                <PPSection>
                    <PPSectionTitle>Contact Us</PPSectionTitle>
                    <PPSubSectionContainer>
                        <PPSectionSubP dangerouslySetInnerHTML={{
                            __html: `
                            If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
                            <br><br>
                            Contact Information:
                            <br>
                            <ul>
                                <li>Email: contact@zmileyi.com</li>
                                <li>Phone: +228 70754545</li>
                            </ul>
                            `
                        }}/>
                    </PPSubSectionContainer>
                </PPSection>
            </PPSectionContainer>
        </PPContainer>
    );
}

export default PP;