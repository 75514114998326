import { Navigate, useRoutes } from "react-router-dom";
import { CLIENT_PAGES } from "./paths";
import ClientLayout from "../layouts/ClientLayout";
import PPPage from "../pages/client/PPPage";

export default function Router() {
    return useRoutes([
      // Others
      // {
      //   path: "*",
      //   element: <OthersLayout/>,
      //   children: [
        //   { path: "500", element: <Page500/> },
        //   { path: "404", element: <NotFound/> },
        //   { path: "*", element: <Navigate to="/404" replace/> },
        // ],
      // },
      // Client
      {
        path: CLIENT_PAGES.home,
        element: <ClientLayout/>,
        children: [
          { element: <PPPage/>, index: true },
        ],
      },
      // Redirection
      { path: "*", element: <Navigate to="/" replace/> },
    ]);
  }