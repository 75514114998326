import Page from "../../components/Pages";
import PP from "../../sections/PPPage/PP";

function PPPage() {
    return (
        <Page title="Politique de confidentialité">
            <PP/>
        </Page>
    );
}

export default PPPage;